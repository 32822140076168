.wrapper {
    width: 100%;
}

.first {
    background: var(--primary) !important;
    color: #fff;
}

@media screen and (min-width: 992px) {
    .wrapper {
        width: 365px;
    }
}

html {
    &[data-theme="dark"] {
        .text {
            color: var(--white);
        }

        .defaultTariff {
            border-color: var(--secondary-b-color);
            background: transparent;
            opacity: 1;
        }

        .first {
            color: var(--primary);
        }
    }
}
