.number {
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background: var(--green-4);

  &::before {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }
}

.description {
  max-width: 400px;
}

.arrow {
  font-size: 24px;
  fill: var(--font-color-primary);
}

.next,
.back {
  align-items: flex-start;
}

.next {
  padding-left: 15px;
  .arrow {
    transform: rotate(270deg);
    fill: var(--button-text-color);
  }
}

.back {
  background: var(--button-color-secondary);
  padding-right: 15px;

  &:not(:disabled):hover {
    box-shadow: 0 0 4px var(--button-color-secondary);
  }

  .arrow {
    transform: rotate(90deg);
    fill: var(--button-text-color-secondary);
  }
}

.slide {
  &-mac {
    content: url(../../../../shared/assets/images/country-devices/ru/mac.png)
      url(../../../../shared/assets/images/country-devices/kz/mac.png)
      url(../../../../shared/assets/images/country-devices/de/mac.png)
      url(../../../../shared/assets/images/country-devices/fr/mac.png)
      url(../../../../shared/assets/images/country-devices/us/mac.png)
      url(../../../../shared/assets/images/country-devices/nl/mac.png)
      url(../../../../shared/assets/images/country-devices/gb/mac.png)
      url(../../../../shared/assets/images/country-devices/sg/mac.png);
  }

  &-windows {
    content: url(../../../../shared/assets/images/country-devices/ru/windows.png)
      url(../../../../shared/assets/images/country-devices/kz/windows.png)
      url(../../../../shared/assets/images/country-devices/de/windows.png)
      url(../../../../shared/assets/images/country-devices/fr/windows.png)
      url(../../../../shared/assets/images/country-devices/us/windows.png)
      url(../../../../shared/assets/images/country-devices/nl/windows.png)
      url(../../../../shared/assets/images/country-devices/gb/windows.png)
      url(../../../../shared/assets/images/country-devices/sg/windows.png);
  }

  &-iphone {
    content: url(../../../../shared/assets/images/country-devices/ru/iphone.png)
      url(../../../../shared/assets/images/country-devices/kz/iphone.png)
      url(../../../../shared/assets/images/country-devices/de/iphone.png)
      url(../../../../shared/assets/images/country-devices/fr/iphone.png)
      url(../../../../shared/assets/images/country-devices/us/iphone.png)
      url(../../../../shared/assets/images/country-devices/nl/iphone.png)
      url(../../../../shared/assets/images/country-devices/gb/iphone.png)
      url(../../../../shared/assets/images/country-devices/sg/iphone.png);
  }

  &-android {
    content: url(../../../../shared/assets/images/country-devices/ru/android.png)
      url(../../../../shared/assets/images/country-devices/kz/android.png)
      url(../../../../shared/assets/images/country-devices/de/android.png)
      url(../../../../shared/assets/images/country-devices/fr/android.png)
      url(../../../../shared/assets/images/country-devices/us/android.png)
      url(../../../../shared/assets/images/country-devices/nl/android.png)
      url(../../../../shared/assets/images/country-devices/gb/android.png)
      url(../../../../shared/assets/images/country-devices/sg/android.png);
  }

  background-repeat: no-repeat;
  background-position: 374px 100%;
  background-size: contain;

  @media (max-width: 1200px) {
    background-position: 368px 100%;
  }

  @media (max-width: 1120px) {
    background-position: 354.4px 100%;
  }

  @media (max-width: 1040px) {
    background-position: 340.8px 100%;
  }

  @media (max-width: 960px) {
    background-position: 327.2px 100%;
  }

  @media (max-width: 880px) {
    background-position: 313.6px 100%;
  }

  @media (max-width: 800px) {
    background-position: 300px 100%;
  }

  @media (max-width: 768px) {
    &,
    &-mac,
    &-windows,
    &-iphone,
    &-android {
      background-image: none !important;
    }

    &::before {
      content: none !important;
    }
  }

  @at-root {
    [data-theme='dark'] & {
      &-ru-mac {
        background-image: url(../../../../shared/assets/images/country-devices/ru/mac.png);
      }
      &-ru-windows {
        background-image: url(../../../../shared/assets/images/country-devices/ru/windows.png);
      }
      &-ru-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/ru/iphone.png);
      }
      &-ru-android {
        background-image: url(../../../../shared/assets/images/country-devices/ru/android.png);
      }

      &-kz-mac {
        background-image: url(../../../../shared/assets/images/country-devices/kz/mac.png);
      }
      &-kz-windows {
        background-image: url(../../../../shared/assets/images/country-devices/kz/windows.png);
      }
      &-kz-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/kz/iphone.png);
      }
      &-kz-android {
        background-image: url(../../../../shared/assets/images/country-devices/kz/android.png);
      }

      &-de-mac {
        background-image: url(../../../../shared/assets/images/country-devices/de/mac.png);
      }
      &-de-windows {
        background-image: url(../../../../shared/assets/images/country-devices/de/windows.png);
      }
      &-de-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/de/iphone.png);
      }
      &-de-android {
        background-image: url(../../../../shared/assets/images/country-devices/de/android.png);
      }

      &-fr-mac {
        background-image: url(../../../../shared/assets/images/country-devices/fr/mac.png);
      }
      &-fr-windows {
        background-image: url(../../../../shared/assets/images/country-devices/fr/windows.png);
      }
      &-fr-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/fr/iphone.png);
      }
      &-fr-android {
        background-image: url(../../../../shared/assets/images/country-devices/fr/android.png);
      }

      &-us-mac {
        background-image: url(../../../../shared/assets/images/country-devices/us/mac.png);
      }
      &-us-windows {
        background-image: url(../../../../shared/assets/images/country-devices/us/windows.png);
      }
      &-us-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/us/iphone.png);
      }
      &-us-android {
        background-image: url(../../../../shared/assets/images/country-devices/us/android.png);
      }

      &-nl-mac {
        background-image: url(../../../../shared/assets/images/country-devices/nl/mac.png);
      }
      &-nl-windows {
        background-image: url(../../../../shared/assets/images/country-devices/nl/windows.png);
      }
      &-nl-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/nl/iphone.png);
      }
      &-nl-android {
        background-image: url(../../../../shared/assets/images/country-devices/nl/android.png);
      }

      &-gb-mac {
        background-image: url(../../../../shared/assets/images/country-devices/gb/mac.png);
      }
      &-gb-windows {
        background-image: url(../../../../shared/assets/images/country-devices/gb/windows.png);
      }
      &-gb-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/gb/iphone.png);
      }
      &-gb-android {
        background-image: url(../../../../shared/assets/images/country-devices/gb/android.png);
      }

      &-sg-mac {
        background-image: url(../../../../shared/assets/images/country-devices/sg/mac.png);
      }
      &-sg-windows {
        background-image: url(../../../../shared/assets/images/country-devices/sg/windows.png);
      }
      &-sg-iphone {
        background-image: url(../../../../shared/assets/images/country-devices/sg/iphone.png);
      }
      &-sg-android {
        background-image: url(../../../../shared/assets/images/country-devices/sg/android.png);
      }
    }
  }
}
