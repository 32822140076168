.popup {
    position: absolute;
    bottom: -144px;
    left: -70px;
    width: 150px;
    border: 1px solid var(--primary-b-color);
    border-radius: 3px;
    background: var(--primary-bg-color);
}

.popupMobile {
    bottom: -100px;
    left: -17px;
    width: 220px;
}

.body {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
    user-select: none;
}

@media screen and (max-width: 768px) {
    .popup {
        bottom: -144px;
    }
}
