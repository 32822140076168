.wrapper {
    position: fixed;
    z-index: -1;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
}

.content {
    position: absolute;
    height: 100%;
    background-color: var(--primary-bg-color);
    transition: all 0.5s;
}

.header {
    height: var(--header-height-m);
    padding: 0 0 0 5px;
    border-bottom: 1px solid var(--primary-b-color);
}

.closeBtn {
    width: 50px;
    height: 100%;
}

.left {
    left: -100%;
}

.right {
    right: -100%;
}

.top {
    top: -100%;
}

.bottom {
    bottom: -100%;
}

.opened {
    z-index: var(--modal-z-index);
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    pointer-events: auto;

    .left {
        left: 0;
        width: 80vw;
    }

    .right {
        right: 0;
        width: 80vw;
    }

    .top {
        top: 0;
        right: 0;
        left: 0;
        height: 50vh;
    }

    .bottom {
        right: 0;
        bottom: 0;
        left: 0;
        height: 50vh;
    }
}

.isClosing {
    .left {
        left: -100%;
    }

    .right {
        right: -100%;
    }

    .top {
        top: -100%;
    }

    .bottom {
        bottom: -100%;
    }
}

html {
    &[data-theme="dark"] {
        .content {
            background: var(--dark-bg-color);
        }

        .closeBtn {
            box-shadow: initial;

            &:hover {
                box-shadow: initial;
            }
        }
    }
}
