@import '../../styles/mixins/scrollbar.scss';

.container {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.container-open {
  .overlay {
    opacity: 1;
    animation: fadeIn 0.3s ease-out forwards;
  }

  .modal {
    transform: scale(0);
    animation: zoomIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}

.container-closed {
  .overlay {
    opacity: 0;
    animation: fadeOut 0.3s ease-out forwards;
  }

  .modal {
    animation: zoomOut 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}

.overlay {
  height: 100%;
  width: 100%;
  background: var(--modal-overlay-bg-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 36px 8px;
  gap: 32px 0;
}

.modal {
  background: var(--modal-bg-color);
  border-radius: var(--modal-border-radius);
  flex: 1;
  display: flex;
  width: 100%;
  max-width: 486px;
  min-width: 256px;
  overflow: hidden;
}

.content {
  position: relative;
  padding: 28px 32px;
  flex: 1;
  overflow: auto;
  @include scrollbar(var(--yellow-1), transparent, 8px);
}

.close {
  position: absolute;
  color: white;
  top: 28px;
  right: 28px;
  padding: 0;
  height: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes zoomOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
