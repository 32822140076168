.content {
  padding: 12px;
  border-radius: 10px;
}

.ellipses {
  font-size: 32px;

  path {
    fill: var(--font-color-primary);
  }
}

.control {
  padding: 0;
  height: auto;
}

.action {
  font-size: 26px;
  justify-content: flex-start;
  gap: 10px;
  padding: 0;
  height: auto;
}

.question {
  fill: var(--font-color-primary);
}

.download {
  stroke: var(--font-color-primary);
}

.swap {
  stroke: var(--font-color-primary);
}

.delete {
  stroke: var(--font-color-primary);
}

.which-device {
  max-width: 224px;
  padding: 10px;
}

.device {
  height: auto;
}

.apple,
.android {
  fill: var(--font-color-primary);
  font-size: 32px;
}
