.button {
  font-size: var(--font-size-3);
  max-width: fit-content;
  height: auto;
}

.download {
  stroke: var(--font-color-primary);
  font-size: 24px;
}
