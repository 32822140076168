.Skeleton {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 2px;
    background-color: #fafafa;

    &::after {
        position: absolute;
        animation: shimmer 2s infinite;
        background-image: linear-gradient(
            90deg,
            rgba(125 125 125 / 0%) 0,
            rgba(125 125 125 / 20%) 20%,
            rgba(125 125 125 / 50%) 50%,
            rgba(125 125 125 / 0%)
        );
        content: '';
        inset: 0;
        transform: translateX(-100%);
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
