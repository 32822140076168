@mixin scrollbar(
  $thumb-color,
  $track-color,
  $width: 16px,
  $thumb-radius: 8px,
  $track-radius: 8px
) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: $track-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: $thumb-radius;
  }

  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;
}
