.content {
  padding: 8px;
}

.dropdown {
  display: flex;
}

.control {
  padding: 0;
  gap: 10px;
  flex: 1;
}

.country {
  justify-content: flex-start;
}

.triangle {
  font-size: 8px;

  path {
    fill: var(--font-color-primary);
  }
}

.empty {
  padding: 24px;
}

.hint {
  max-width: 400px;
}
