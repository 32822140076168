:root {
  --font-family-main: 'Inter', sans-serif;
  --font-family-heading: 'Jura', sans-serif;

  --font-size-1: 14px;
  --font-line-1: 1.2;
  --font-1: var(--font-size-1) / var(--font-line-1) var(--font-family-main);

  --font-size-2: 16px;
  --font-line-2: 1.2;
  --font-2: var(--font-size-2) / var(--font-line-2) var(--font-family-main);

  --font-size-3: 18px;
  --font-line-3: 1.2;
  --font-3: var(--font-size-3) / var(--font-line-3) var(--font-family-main);

  --font-size-4: 20px;
  --font-line-4: 1.2;
  --font-4: var(--font-size-4) / var(--font-line-4) var(--font-family-main);

  --font-size-5: 22px;
  --font-line-5: 1.2;
  --font-5: var(--font-size-5) / var(--font-line-5) var(--font-family-main);

  --font-size-6: 26px;
  --font-line-6: 1.2;
  --font-6: var(--font-size-6) / var(--font-line-6) var(--font-family-main);

  --font-size-7: 32px;
  --font-line-7: 1.2;
  --font-7: var(--font-size-7) / var(--font-line-7) var(--font-family-main);

  --font-size-8: 36px;
  --font-line-8: 1.2;
  --font-8: var(--font-size-8) / var(--font-line-8) var(--font-family-main);

  --font-size-heading-1: 24px;
  --font-line-heading-1: 1.2;
  --font-heading-1: var(--font-size-heading-1) / var(--font-line-heading-1)
    var(--font-family-heading);

  --font-size-heading-2: 28px;
  --font-line-heading-2: 1.2;
  --font-heading-2: var(--font-size-heading-2) / var(--font-line-heading-2)
    var(--font-family-heading);

  --font-size-heading-3: 32px;
  --font-line-heading-3: 1.2;
  --font-heading-3: var(--font-size-heading-3) / var(--font-line-heading-3)
    var(--font-family-heading);

  --font-size-heading-4: 36px;
  --font-line-heading-4: 1.2;
  --font-heading-4: var(--font-size-heading-4) / var(--font-line-heading-4)
    var(--font-family-heading);

  --font-size-heading-5: 42px;
  --font-line-heading-5: 1.2;
  --font-heading-5: var(--font-size-heading-5) / var(--font-line-heading-5)
    var(--font-family-heading);

  --green-1: #00d940;
  --green-2: #1fce5633;
  --green-3: #1fce561a;
  --green-4: #1fce56;
  --yellow-1: #ffa800;
  --yellow-2: #ffa80033;
  --yellow-3: #ffa8001a;
  --red-1: #fe6666;
  --red-2: #fe666633;
  --red-3: #fe66661a;
  --gray-1: #9ea8b0;
  --gray-2: #6e7d891a;
  --gray-3: #6e7d8933;
  --dark-gray: #525e67;
  --light-gray: #6e7d89;
  --white: #fff;
  --black: #000;

  --modal-z-index: 5;
  --popover-z-index: 10;
  --popconfirm-z-index: 15;
}

:root[data-theme='dark'] {
  --primary-color: var(--green-1);
  --primary-bg-color: #202b39;
  --font-color-primary: var(--white);
  --font-color-secondary: var(--light-gray);
  --font-color-tertiary: var(--gray-1);
  --font-color-success: var(--green-1);
  --font-color-warning: var(--yellow-1);
  --font-color-danger: var(--red-1);

  --color-fill-1: #6e7d8933;
  --color-fill-2: #525e6733;
  --color-fill-3: #2b3544;
  --color-fill-4: #000b1b;
  --color-fill-5: #d9d9d91a;
  --color-fill-6: #ffffff05;
  --color-fill-7: #ffffff0d;
  --color-fill-8: #ffffff05;
  --color-fill-9: #525e6733;
  --color-fill-10: #ffffff3d;
  --color-fill-11: #374253;
  --color-fill-12: #6e7d897a;

  --border-color-1: #36404c;
  --border-color-2: #262f3d;
  --border-color-3: #d9d9d91a;
  --border-color-4: #ffffff0d;
  --border-color-5: #ffffff33;
  --border-color-6: #444e54;
  --border-color-7: #ffffff1a;

  --layout-bg-color: #0e192a;

  --container-bg-color: var(--primary-bg-color);
  --container-border-color: var(--border-color-1);
  --container-border-radius: 20px;

  --input-fill-color: var(--color-fill-1);
  --input-font-color: var(--font-color-primary);
  --input-label-color: var(--font-color-secondary);
  --input-label-color-active: var(--primary-color);
  --input-border-color: var(--container-border-color);
  --input-border-color-active: var(--primary-color);
  --input-font-size: var(--font-size-2);
  --input-padding: 10px;
  --input-border-radius: 10px;

  --link-font-color-primary: var(--primary-color);
  --link-font-color-secondary: var(--font-color-secondary);

  --button-color-primary: var(--yellow-1);
  --button-color-secondary: var(--dark-gray);
  --button-color-disabled: var(--gray-1);
  --button-text-color: var(--font-color-primary);
  --button-text-color-secondary: var(--font-color-primary);
  --button-border-radius-s: 8px;
  --button-border-radius-m: 10px;
  --button-border-radius-l: 10px;
  --button-border-color: var(--white);
  --button-height-s: 30px;
  --button-height-m: 42px;
  --button-height-l: 48px;
  --button-font-size-s: var(--font-size-1);
  --button-font-size-m: var(--font-size-2);
  --button-font-size-l: var(--font-size-4);
  --button-padding-s: 0 10px;
  --button-padding-m: 10px;
  --button-padding-l: 10px;

  --checkbox-color: var(--light-gray);
  --checkbox-color-active: var(--primary-color);
  --checkbox-font-color: var(--font-color-secondary);
  --checkbox-font-size: var(--font-size-1);
  --checkbox-size: 18px;

  --radio-color: var(--primary-color);
  --radio-size: 16px;
  --radio-font-color: var(--font-color-primary);
  --radio-border-color: var(--border-color-3);
  --radio-bg-color: var(--color-fill-5);
  --radio-bg-color-active: var(--white);

  --modal-bg-color: #202b39;
  --modal-overlay-bg-color: #0e192acc;
  --modal-border-radius: 20px;

  --select-font-color: var(--font-color-primary);
  --select-border-color: #3f4956;
  --select-border-color-active: var(--green-1);
  --select-bg-color: var(--color-fill-1);
  --select-option-bg: var(--yellow-1);
  --select-option-bg-active: var(--yellow-3);
  --select-menu-bg: #353f4d;
  --select-placeholder-color: var(--font-color-secondary);

  --society-color: var(--font-color-primary);
  --society-bg-color: var(--color-fill-12);
}

:root[data-theme='light'] {
  --gray-1: #9ea8b0;
  --gray-2: #eef3f7;
  --gray-3: #6e7d8933;

  --primary-color: var(--green-1);
  --primary-bg-color: var(--white);
  --font-color-primary: #262f37;
  --font-color-secondary: var(--light-gray);
  --font-color-tertiary: var(--gray-1);
  --font-color-success: var(--green-1);
  --font-color-warning: var(--yellow-1);
  --font-color-danger: var(--red-1);

  --color-fill-1: #eef3f7;
  --color-fill-2: #eef3f7;
  --color-fill-3: #eef3f7;
  --color-fill-4: var(--white);
  --color-fill-5: #9ea8b04d;
  --color-fill-6: #ffffff;
  --color-fill-7: var(--white);
  --color-fill-8: #eef3f7;
  --color-fill-9: #525e6733;
  --color-fill-10: #ffffff3d;
  --color-fill-11: #d8e1e8;

  --border-color-1: #0000001a;
  --border-color-2: #20293026;
  --border-color-3: #d9d9d91a;
  --border-color-4: #0000000d;
  --border-color-5: #262f3733;
  --border-color-6: #9ea8b033;
  --border-color-7: #ffffff1a;

  --layout-bg-color: #e5eaf4;

  --container-bg-color: var(--primary-bg-color);
  --container-border-color: var(--border-color-1);
  --container-border-radius: 20px;

  --input-fill-color: var(--color-fill-1);
  --input-font-color: var(--font-color-primary);
  --input-label-color: var(--font-color-secondary);
  --input-label-color-active: var(--primary-color);
  --input-border-color: var(--container-border-color);
  --input-border-color-active: var(--primary-color);
  --input-font-size: var(--font-size-2);
  --input-padding: 10px;
  --input-border-radius: 10px;

  --link-font-color-primary: var(--primary-color);
  --link-font-color-secondary: var(--font-color-secondary);

  --button-color-primary: var(--yellow-1);
  --button-color-secondary: #d8e1e8;
  --button-color-disabled: var(--gray-1);
  --button-text-color: var(--white);
  --button-text-color-secondary: var(--font-color-primary);
  --button-border-radius-s: 8px;
  --button-border-radius-m: 10px;
  --button-border-radius-l: 10px;
  --button-border-color: var(--font-color-primary);
  --button-height-s: 30px;
  --button-height-m: 42px;
  --button-height-l: 48px;
  --button-font-size-s: var(--font-size-1);
  --button-font-size-m: var(--font-size-2);
  --button-font-size-l: var(--font-size-4);
  --button-padding-s: 0 10px;
  --button-padding-m: 10px;
  --button-padding-l: 10px;

  --checkbox-color: var(--light-gray);
  --checkbox-color-active: var(--primary-color);
  --checkbox-font-color: var(--font-color-secondary);
  --checkbox-font-size: var(--font-size-1);
  --checkbox-size: 18px;

  --radio-color: var(--primary-color);
  --radio-size: 16px;
  --radio-font-color: var(--font-color-primary);
  --radio-border-color: var(--font-color-primary);
  --radio-bg-color: var(--color-fill-5);
  --radio-bg-color-active: var(--white);

  --modal-bg-color: var(--container-bg-color);
  --modal-overlay-bg-color: #0e192acc;
  --modal-border-radius: 20px;

  --select-font-color: var(--font-color-primary);
  --select-border-color: #3f4956;
  --select-border-color-active: var(--green-1);
  --select-bg-color: #eef3f7;
  --select-option-bg: var(--yellow-1);
  --select-option-bg-active: var(--yellow-3);
  --select-menu-bg: #dee8f0;
  --select-placeholder-color: var(--font-color-secondary);

  --society-color: var(--font-color-secondary);
  --society-bg-color: var(--color-fill-3);
}
