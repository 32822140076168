.container {
  padding: 12px 0;
}

.error {
  font-size: 64px;
  fill: var(--red-1);
}

.empty {
  font-size: 64px;
  fill: var(--font-color-secondary);
}
