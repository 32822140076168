
.wrapper {
    button {
        padding: 10px 20px;

        &:hover {
            background: var(--primary);
        }
    }
}

