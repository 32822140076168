.button {
  background: var(--color-fill-4);
  width: 60px;
  font-size: 26px;
}

.icon {
  path {
    fill: var(--font-color-primary);
  }
}
