.connection-add {
  height: auto;
  border: 1px dashed var(--border-color-5);

  &:not(.connection-add-disabled) {
    .plus {
      font-size: 72px;
      line-height: 1;

      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
    }
  }
}

.hint {
  max-width: 400px;
}
