.text {
  font: var(--font-1);
}

.text-size-1 {
  font: var(--font-1);
}

.text-size-2 {
  font: var(--font-2);
}

.text-size-3 {
  font: var(--font-3);
}

.text-size-4 {
  font: var(--font-4);
}

.text-size-5 {
  font: var(--font-5);
}

.text-size-6 {
  font: var(--font-6);
}

.text-size-7 {
  font: var(--font-7);
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-default {
  color: var(--font-color-primary);
}

.text-secondary {
  color: var(--font-color-secondary);
}

.text-tertiary {
  color: var(--font-color-tertiary);
}

.text-success {
  color: var(--font-color-success);
}

.text-warning {
  color: var(--font-color-warning);
}

.text-danger {
  color: var(--font-color-danger);
}

.text-strong {
  font-weight: 600;
}

.title {
  color: var(--font-color-primary);
}

h1.title {
  font: var(--font-heading-5);
}

h2.title {
  font: var(--font-heading-4);
}

h3.title {
  font: var(--font-heading-3);
}

h4.title {
  font: var(--font-heading-2);
}

h5.title {
  font: var(--font-heading-1);
}

@media screen and (max-width: 768px) {
  h1.title,
  h2.title,
  h3.title,
  h4.title,
  h5.title {
    font: var(--font-heading-1);
  }
}

.link {
  font: var(--font-1);
  transition: opacity 0.2s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.link-primary {
  color: var(--link-font-color-primary);
}

.link-secondary {
  color: var(--link-font-color-secondary);
}

.mark {
  display: inline-block;
  width: fit-content;
  border-radius: 10px;
  padding: 12.5px 20px;
  background-color: var(--color-fill-1);
}
