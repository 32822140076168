.container {
  overflow: hidden;
}

.form {
  padding: 24px;

  button {
    width: fit-content;
    padding: 10px 16px;
  }
}

.img {
  flex: 1 0 auto;

  img {
    object-fit: fill;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
