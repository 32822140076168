.group + .group {
  border-top: 1px solid var(--border-color-2);
}

.group:not(:first-child) {
  padding-top: 20px;
}

.group:not(:last-child) {
  padding-bottom: 20px;
}

.language {
  padding: 0;
}
