.container {
  $scroll-offset: 12px;

  @media (max-width: 768px) {
    width: calc(100vw);
    margin-left: calc(-50vw + 50%);
  }

  width: calc(100vw - $scroll-offset);
  margin-left: calc(-50vw + $scroll-offset / 2 + 50%);

  :global(.slick-slide) {
    padding: 0 16px;

    @media screen and (max-width: 512px) {
      padding: 0 8px;
    }
  }

  :global(.slick-slider) {
    margin-bottom: -256px;
    transform: translateY(-128px);
  }

  :global(.slick-active) {
    position: relative;
  }
}

.slide {
  margin: 128px 0;
  position: relative;
}

.slide-active {
  &::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 20%;
    bottom: 40%;
    right: 20%;
    border-radius: 100%;
    box-shadow: 0 0 196px 128px var(--green-1);
    z-index: -1;
    opacity: 1;
  }
}

.slide-inactive {
  max-height: 604px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;
    background: var(--primary-bg-color);
  }
}
