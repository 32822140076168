.error {
    .text {
        color: var(--secondary-error-color);
    }
}

.button {
    margin-top: 20px;
}

.wrapper {
    width: 300px;
}

.defaultTariff {
    display: flex;
    height: 113px;
    justify-content: flex-end;
    padding: 20px;
    border: 2px solid #C5C5C5;
    border-radius: 30px;
    background: #FFF;
}

.day {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.price {
    font-size: 63px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.info {
    margin: 10px 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

@media screen and (max-width: 992px) {
    .wrapper {
        width: 250px;
    }
}
@media screen and (max-width: 767px) {
    .wrapper {
        width: 100%;
    }
}

html {
    &[data-theme="dark"] {
        .text {
            color: var(--white);
        }

        .defaultTariff {
            border-color: var(--secondary-b-color);
            background: transparent;
            opacity: 1;
        }
    }
}
