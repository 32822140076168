.slide {
  display: flex;
  gap: 36px;
  min-height: 569px;
  padding: 36px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media screen and (max-width: 512px) {
    padding: 20px;
  }
}

.left {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
}

.right {
  flex: 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.controls {
  position: relative;
  align-self: flex-end;
  margin-top: 488px;
  top: 0;
  right: 0;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    flex: 1;
  }
}
