.overlay {
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    background-color: var(--primary-bg-color);
}

html {
    &[data-theme="dark"] {
        .overlay {
            background-color: var(--dark-bg-color);
            color: #fff;
        }
    }
}

@media screen and (min-width: 992px) {
    .overlay {
        flex-direction: row;
        gap: 112px;
    }
}

@media screen and (min-width: 1900px) {
    .overlay {
        gap: 312px;
    }
}

