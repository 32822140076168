.footer {
  display: flex;
  padding: 72px 90px;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    padding: 32px 20px;
  }
}

.logo {
  svg {
    path {
      fill: var(--light-gray);
    }
  }
}

.navigation {
  display: flex;
  gap: 40px;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    gap: 16px;
  }
}

.society {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--society-bg-color);
  font-size: 24px;
  height: 36px;
  width: 36px;
  border-radius: 50%;

  svg {
    path {
      fill: var(--society-color);
    }
  }
}
