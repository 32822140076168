.text {
    color: var(--primary-text-color);
    font: var(--font-m);
}

.error {
    .text {
        color: var(--secondary-error-color);
    }
}

// размер шрифта
.size_s {
    .text {
        font: var(--font-s);
    }
}

.size_m {
    .text {
        font: var(--font-m);
    }
}

.size_l {
    .text {
        font: var(--font-l);
    }
}

.size_xl {
    .text {
        font: var(--font-xl);
    }
}

.size_xxl {
    .text {
        font: var(--font-xxl);
    }
}

// выравнивание
.right {
    text-align: right;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

// насыщенность
.light {
    .text {
        font-weight: 300;
    }
}

.normal {
    .text {
        font-weight: 400;
    }
}

.bold {
    .text {
        font-weight: 700;
    }
}

.emptyBlock {
    cursor: auto;
    opacity: 0;
    user-select: none;
}

html {
    &[data-theme="dark"] {
        .text {
            color: var(--white);
        }
    }
}
