@import '~shared/styles/mixins/scrollbar.scss';

.wrapper {
  border-radius: 15px;
  max-width: 332px;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--color-fill-6);
  background: var(--color-fill-3);

  .content {
    transition-property: height;
    transition-duration: 300ms;
    overflow-y: auto;

    max-height: 216px;
    @include scrollbar(#5e6571, transparent, 8px);
  }

  .icon {
    &.open {
      transform: rotate(180deg);
    }
  }

  .option {
    height: 72px;
    display: flex;
    padding: 20px;
    justify-content: space-between;

    cursor: pointer;

    &.active {
      background: var(--color-fill-11);
    }
  }

  .flag {
    height: 32px;
    width: 32px;
  }

  .flagPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: var(--green-4);
  }

  .option-content {
    align-items: center;
    display: flex;
    gap: 20px;
  }
}
