.wrapper {
    position: relative;
    width: 100%;
}

.wrapperMobile {
    font-size: 24px;
}

.button {
    padding: 0;
    border: none;
    background: transparent;
    color: var(--primary-text-color);
    cursor: pointer;
    user-select: none;

    &:hover {
        border-bottom: 1px solid var(--primary-text-color);
    }
}

.buttonMobile {
    padding: 0 0 5px;
    font-size: 24px;
}

@media screen and (max-width: 767px) {
    .button {
        &:hover {
            border-bottom: 0;
        }
    }
}
