.connection {
  position: relative;
  border-radius: var(--container-border-radius);
  background-color: var(--color-fill-7);
  max-width: 378px;
  border: 1px solid var(--border-color-4);
}

.actions {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 32px;
}

.description {
  padding: 16px 20px 25px 20px;
}

.copy {
  padding: 8px 0;
  font-size: 24px;
  height: auto;
  flex: 1;
  justify-content: space-between;
}

.badge {
  position: absolute;
  top: 16px;
  left: 16px;
  max-width: 140px;
  width: 100%;
  display: flex;
  font-size: 14px;

  @at-root [data-theme='dark'] & {
    color: white;
    background: color-mix(in srgb, white 10%, transparent);
  }

  @at-root [data-theme='light'] & {
    color: black;
    background: color-mix(in srgb, black 10%, transparent);
  }

  padding: 5px;
  height: 27px;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
}
