.BurgerButton {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

.button {
    position: relative;
    top: 2px;
    display: flex;
    width: 22px;
    height: 15px;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 100%;
    height: 3px;
    background-color: var(--primary);

    &::before {
        position: absolute;
        top: 0;
        width: 100%;
        height: 3px;
        background-color: var(--primary);
        content: '';
    }

    &::after {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: var(--primary);
        content: '';
    }
}

@media screen and (min-width: 414px) {
    .BurgerButton {
        width: 35px;
        height: 35px;
    }

    .button {
        width: 100%;
        height: 21px;
    }

    .icon {
        height: 4px;

        &::before {
            height: 4px;
        }

        &::after {
            height: 4px;
        }
    }
}
