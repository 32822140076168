.description {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.send {
  padding: 0;
  height: auto;
  font: var(--font-1);
  font-weight: normal;
}

.mail {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.envelope {
  height: 24px;
  width: 24px;
}

.footer,
.hint {
  display: flex;
  gap: 4px;
}
