.wrapper {
    margin-top: 42px;
}

.action {
    width: 100%;
}

.modal {
    max-width: 690px;
    width: 100%;
}