.copy {
  padding: 8px 0;
  font-size: 24px;
  height: auto;
  flex: 1;
  justify-content: space-between;
}

.copy-disabled {
  justify-content: flex-start;
}

.copy-filled {
  padding: 12.5px 20px;
  gap: 10px;
  background: var(--color-fill-11);
}

.arrow {
  transform: rotate(270deg);

  path {
    fill: var(--font-color-secondary);
  }
}

.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon1 {
  path {
    fill: var(--font-color-secondary);
  }
}

.icon2 {
  stroke: var(--font-color-primary);
}

.truncated {
  display: flex;

  max-width: 280px;

  .text {
    max-width: 260px;
  }
}
