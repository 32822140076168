.Select {
    position: relative;
    width: 100%;
}

.header {
    position: relative;
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    padding: 2px 5px;
    border-radius: 3px;
    margin-bottom: 5px;
    background: transparent;
    color: currentcolor;
    cursor: pointer;
    user-select: none;

    &.open {
        border: 1px solid currentcolor;
    }

    &.readonly {
        border: 1px solid var(--disabled-bg-color);
        cursor: auto;
        user-select: text;
    }
}

.icon {
    right: 10px;
    width: 12px;
    margin-left: auto;
    fill: currentcolor;

    &.readonly {
        display: none;
    }
}

.popup {
    position: absolute;
    z-index: 2;
    width: 150px;
    border: 1px solid var(--black);
    border-radius: 3px;
    background: var(--primary-bg-color);
}

.body {
    width: 100%;
    overflow-y: auto;
    user-select: none;
}

.option {
    padding: 1px 8px;

    &:hover {
        background: var(--dark-bg-color);
        color: var(--white);
        opacity: 0.8;
    }

    @media (max-width: 991px) {
        padding: 2px 8px;
    }
}

.selected {
    background: var(--dark-bg-color);
    color: var(--white);
    opacity: 0.5;
}

html {
    &[data-theme="dark"] {
        .header {
            color: var(--white);
        }

        .popup {
            background: var(--black);
            color: var(--white);
        }

        .selected {
            background: var(--secondary-b-color);
            color: var(--white);
        }

        .option {
            color: var(--white);
        }
    }
}
