.header {
  display: flex;
  justify-content: space-between;
}

.nav {
  display: flex;
  gap: 20px;
}

.button {
  background: var(--color-fill-4);
  padding: 12.5px 20px;
  gap: 10px;
}

.plus {
  font-size: 16px;
  path {
    stroke: var(--font-color-primary);
  }
}

.button-active {
  .plus {
    transform: rotate(45deg);
  }
}
