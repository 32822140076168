.logo {
  display: flex;
  align-items: center;
  font-family: var(--font-family-heading);
  color: var(--font-color-primary);

  svg {
    height: 100%;
  }
}

.logo-large {
  height: 48px;
  font-size: var(--font-size-5);
  gap: 22px;
}

.logo-middle {
  height: 36px;
  font-size: var(--font-size-4);
  gap: 17px;
}

.logo-small {
  height: 24px;
  font-size: var(--font-size-3);
  gap: 12px;
}

.redirect {
  cursor: pointer;
}
