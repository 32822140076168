.tariffTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  color: #000;
  font-family: var(--font-family-heading);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    margin-left: 16px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: translateY(3px);
  }
}

.section {
  padding: 0 6px;
}

.input {
  width: 100%;
  padding: 30px 18px 11px;
  border-radius: 50px;
  margin-bottom: 10px;
  background: linear-gradient(0deg, #C9CCDA 0%, #C9CCDA 100%), #EBECF0;
  background-blend-mode: soft-light, normal;
  box-shadow: 2px 2px 3px 0 #BDC1D1 inset, -2px -2px 6px 0 #FFF inset;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inputPayed {
  font-weight: 700;
}

.label {
  top: 9px !important;
  left: 28px !important;
}

.header {
  margin-bottom: 5px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.column {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  .section {
    padding: 0 30px;
  }

  .column {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 35px;
  }

  .input {
    width: 290px;
    padding: 33px 30px 13px;
  }

  .tariffTitle {
    align-items: center;
    margin-top: 23px;
    font-size: 24px;

    span {
      margin-left: 23px;
      font-size: 24px;
      font-weight: 400;
    }
  }
}
@media screen and (min-width: 1124px) {
  .input {
    width: 330px;
  }
}
@media screen and (min-width: 1200px) {
  .input {
    width: 370px;
  }
}

html {
  &[data-theme="dark"] {
    .section {
      color: var(--white);
    }

    .tariffTitle {
      color: var(--white);

      span {
        color: var(--white);
      }
    }

    .input {
      border: 1px solid var(--secondary-b-color);
      background: transparent;
      box-shadow: initial;
      color: var(--white);
    }
  }
}
