.container {
  margin-top: 64px;
  text-align: center;
  white-space: pre-line;
}

.post {
  width: 300px;
  height: 200px;
}

.button {
  align-self: normal;
}
