.current.current {
  background: transparent;
  border: 1px solid var(--container-border-color);
}

.body {
  margin-top: 75px;
}

.tariff:not(.tariff-beneficial) {
  .buy {
    background: var(--primary-color);
  }
}

.infinity {
  fill: currentColor;
  display: flex;
  font-size: var(--font-size-3);
}

.paid {
  --color-fill-6: var(--color-fill-8);
  --color-fill-2: var(--color-fill-9);
}

.promocode {
  align-self: flex-start;
  padding: 0;
  height: auto;
}

.promocode-modal {
  flex: 0 1 auto;
}

.buy p {
  color: var(--button-text-color-primary);
}

.disclaimer {
  margin-top: 24px;
}
