.tag {
  display: inline-block;
  padding: 5px;
  border-radius: 12px;
  font: var(--font-1);
}

.tag-gray {
  border: 1px solid var(--gray-3);
  background-color: var(--gray-2);
  color: var(--gray-1);
}

.tag-green {
  border: 1px solid var(--green-3);
  background-color: var(--green-2);
  color: var(--green-1);
}

.tag-red {
  border: 1px solid var(--red-3);
  background-color: var(--red-2);
  color: var(--red-1);
}

.tag-yellow {
  border: 1px solid var(--yellow-3);
  background-color: var(--yellow-2);
  color: var(--yellow-1);
}
