.container {
  margin-top: 28px;
  text-align: center;
  white-space: pre-line;
}

.user {
  width: 300px;
  height: 200px;
}

.button {
  align-self: normal;
}
