.credential {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.data {
    overflow: hidden;
    width: 253px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.icon {
    margin-left: 19px;
}

.noSelect {
    user-select: none;
}

@media (min-width: 992px) {
    .data {
        width: auto;
    }
}

html {
    &[data-theme="dark"] {
        .data {
            color: var(--white);
        }
    }
}
