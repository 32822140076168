.button {
    border: 2px solid var(--primary);
    border-radius: 15px;
    margin-top: 11px;
    background: #FFF;
    color: #000;
    font-size: 28.129px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 2px solid var(--primary);
    border-radius: 30px;
    background: #FFF;
}

.currentCard {
    padding: 20px;
    border: 0 solid transparent;
    border-radius: 30px;
    background: var(--primary);
    background: linear-gradient(133deg, rgb(0 255 194 / 68%) 24.04%, #00D408 63.01%);
    color: #FFF;
}

.currentButton {
    color: var(--primary);

    &:hover {
        color: var(--primary);
    }
}

.period {
    width: 100%;
    padding: 14px 0;
    border-radius: 15px;
    background: #FFF;
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.currentPeriod {
    color: var(--primary);
    font-size: 33px;
}

.price {
    margin: 35px 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.info {
    font-size: 22px;
    font-style: normal;
    line-height: normal;
    text-align: center;
}

.accessCount {
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    text-align: center;
}

.accessCountIndent {
    margin-top: 40px;
}

.salePrice {
    text-decoration: line-through;
}

.sale {
    margin-top: 20px;
}

.saleWord {
    margin-right: 10px;
}

@media screen and (min-width: 991px) {
    .currentCard {
        height: 473px;
    }
}

html {
    &[data-theme="dark"] {
        .card {
            border-color: var(--secondary-b-color);
            background: transparent;
        }

        .currentCard {
            background: linear-gradient(133deg, rgb(0 255 194 / 68%) 24.04%, #00D408 63.01%);
        }

        .button {
            border-color: var(--secondary-b-color);
            background: transparent;
            box-shadow: initial;
        }

        .currentButton {
            border-color: var(--white);
            background: var(--white);
            box-shadow: initial;
            color: var(--primary);

            &:hover {
                color: var(--white);
            }
        }
    }
}
