.line {
  width: 100%;
  height: 2px;
  background: #000;
}

html {
  &[data-theme="dark"] {
    .line {
      background: var(--secondary-b-color);
    }
  }
}
