.picture {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 186px;
    justify-content: center;
}

.logo {
    position: relative;
    bottom: -10%;
    width: 345px;
    height: 346px;
}
@media screen and (min-width: 992px) {
    .picture {
        width: 44%;
        min-height: 114vh;
    }

    .logo {
        width: 855px;
        height: 856px;
        margin: 89px 0 0 -174px;
    }
}

@media screen and (min-width: 1441px) {
    .logo {
        width: 1255px;
        height: 1256px;
    }
}
