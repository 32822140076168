.wrapper {
    width: 300px;
}

@media screen and (max-width: 992px) {
    .wrapper {
        width: 250px;
    }
}
@media screen and (max-width: 767px) {
    .wrapper {
        width: 100%;
    }
}
