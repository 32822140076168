.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 5px;
  font-weight: 600;
  color: var(--button-text-color);
  transition: opacity 0.2s ease, box-shadow 0.15s cubic-bezier(0, 0, 1, 1),
    filter 0.2s ease, background 0.3s ease;
  overflow: hidden;

  &:disabled {
    cursor: default;
  }
}

.button-type-primary {
  background-color: var(--button-color-primary);
  border: none;

  &:disabled {
    background-color: var(--button-color-disabled);
  }

  &:not(:disabled):hover {
    filter: brightness(1.05);
    box-shadow: 0 0 4px var(--button-color-primary);
  }

  &:not(:disabled):active {
    filter: brightness(0.85);
  }
}

.button-type-secondary {
  background-color: var(--button-color-secondary);
  border: none;
  color: var(--button-text-color-secondary);

  &:disabled {
    background-color: var(--button-color-disabled);
  }

  &:not(:disabled):hover {
    filter: brightness(1.05);
    box-shadow: 0 0 4px var(--button-color-secondary);
  }

  &:not(:disabled):active {
    filter: brightness(0.85);
  }
}

.button-type-link {
  background-color: unset;
  border: none;
  color: var(--link-font-color-primary);

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:not(:disabled):active {
    opacity: 0.6;
  }
}

.button-type-text {
  background-color: unset;
  border: none;
  color: var(--font-color-primary);

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:not(:disabled):active {
    opacity: 0.6;
  }
}

.button-type-default {
  background-color: var(--primary-bg-color);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color-secondary);

  &:disabled:not(.button-ghost) {
    background-color: var(--button-color-disabled);
  }

  &:not(:disabled):hover {
    filter: brightness(0.8);
    box-shadow: 0 0 4px var(--button-border-color);
  }

  &:not(:disabled):active {
    filter: brightness(0.6);
  }
}

.button-size-large {
  font-size: var(--button-font-size-l);
  padding: var(--button-padding-l);
  height: var(--button-height-l);
}

.button-size-middle {
  font-size: var(--button-font-size-m);
  padding: var(--button-padding-m);
  height: var(--button-height-m);
}

.button-size-small {
  font-size: var(--button-font-size-s);
  padding: var(--button-padding-s);
  height: var(--button-height-s);
}

.button-shape-default.button-size-large {
  border-radius: var(--button-border-radius-l);
}

.button-shape-default.button-size-middle {
  border-radius: var(--button-border-radius-m);
}

.button-shape-default.button-size-small {
  border-radius: var(--button-border-radius-s);
}

.button-shape-circle {
  border-radius: 100%;
  padding: 7px;
}

.button-shape-circle.button-size-large {
  width: var(--button-height-l);
}

.button-shape-circle.button-size-middle {
  width: var(--button-height-m);
}

.button-shape-circle.button-size-small {
  width: var(--button-height-s);
}

.button-shape-round.button-size-large {
  border-radius: calc(var(--button-border-radius-l) * 2);
}

.button-shape-round.button-size-middle {
  border-radius: calc(var(--button-border-radius-m) * 2);
}

.button-shape-round.button-size-small {
  border-radius: calc(var(--button-border-radius-s) * 2);
}

.prefix,
.content,
.suffix {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.button-loading {
  position: relative;
  color: transparent;
  cursor: default;
  .content {
    opacity: 0;
  }
}

.button-loading::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border: 2px solid #fff;
  border-top: 2px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.button-danger {
  color: var(--red-1);
  border-color: var(--red-1);

  &:not(:disabled):hover {
    box-shadow: 0 0 4px var(--red-1);
  }
}

.button-ghost {
  background: transparent;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
