@import '../../styles/mixins/scrollbar.scss';

$dropdown-background: var(--color-fill-4);
$dropdown-border-width: 1px;
$dropdown-border-color: transparent;
$border-radius: 20px;
$padding: 20px;

.dropdown-disabled {
  .trigger {
    cursor: default;
  }
}

.trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.content {
  position: absolute;
  padding: $padding;
  background-color: $dropdown-background;
  border: 1px solid $dropdown-border-color;
  border-radius: $border-radius;
  animation-fill-mode: forwards;
  transform: translateY(-10000px);
  opacity: 0;
  visibility: hidden;
  display: flex;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.container {
  overflow: auto;
  flex: 1;

  @media screen and (min-width: 769px) {
    @include scrollbar(var(--yellow-1), transparent, 8px);
  }
}

.content-visible {
  opacity: 1;
  visibility: visible;
  animation: growFromTrigger 0.1s ease-out;
  transform: translate(0px);
}

.content-hidden {
  opacity: 0;
  visibility: hidden;
  animation: backToTrigger 0.1s ease-out;
}

@keyframes growFromTrigger {
  from {
    opacity: 0;
    transform: scale(0) translateY(30px);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
    visibility: visible;
  }
}

@keyframes backToTrigger {
  from {
    opacity: 1;
    transform: scale(1) translateY(0);
    visibility: visible;
  }
  to {
    opacity: 0;
    transform: scale(0) translateY(30px);
    visibility: hidden;
  }
}
