.Select {
    position: relative;
    width: 100%;
}

.header {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 30px;
    align-items: center;
    padding: 2px 5px;
    border: 1px solid var(--secondary-b-color);
    border-radius: 3px;
    margin-bottom: 5px;
    background: transparent;
    cursor: pointer;
    user-select: none;

    &.open {
        //border: 1px solid var(--primary-text-color);
    }

    &.readonly {
        border: 1px solid var(--disabled-bg-color);
        cursor: auto;
        user-select: text;
    }
}

.icon {
    right: 10px;
    width: 12px;
    margin-left: auto;
    fill: var(--primary-text-color);

    &.readonly {
        display: none;
    }
}

.popup {
    position: absolute;
    width: 100%;
    //border: 1px solid var(--primary-b-color);
    border: 1px solid var(--black);
    border-radius: 3px;
    //background: var(--secondary-b-color);
    background: var(--primary-bg-color);
    z-index: 2;
}

.body {
    width: 100%;
    overflow-y: auto;
    user-select: none;
}

.option {
    padding: 1px 8px;

    &:hover {
        background: var(--dark-bg-color);
        color: var(--white);
        //opacity: 0.5;
        opacity: 0.8;
    }

    @media (max-width: 991px) {
        padding: 2px 8px;
    }
}

.selected {
    //background: var(--primary-b-color);
    background: var(--dark-bg-color);
    color: var(--white);
    opacity: 0.5;
    &:hover {
        color: var(--white);
    }
}


html {
    &[data-theme="dark"] {
        .popup {
            background: var(--black);
            color: var(--white);
        }
        .selected {
            background: var(--secondary-b-color);
            color: var(--white);
            //color: var(--dark-input-bg);
            &:hover {
                color: var(--white);
            }
        }
        .option {
            color: var(--white);
        }
    }
}
