.collapse {
  gap: 32px 0;
}

.collapse-closed {
  border-bottom: 1px solid var(--border-color-1);
}

.arrow {
  font-size: 20px;
}

.tariffs {
  padding-top: 16px;
  margin-top: 1px;

  & > * {
    flex: 1 1 278px;
  }
}
