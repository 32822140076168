$size: var(--radio-size);
$bg-color: var(--radio-bg-color);
$bg-color-active: var(--radio-bg-color-active);
$active-color: var(--radio-color);
$border-color: var(--radio-border-color);
$font-color: var(--radio-font-color);

.radio {
  display: flex;
  align-items: center;
  gap: 0 calc($size * 0.5);
  cursor: pointer;
  color: $font-color;

  &:not(.radio-disabled):hover {
    .text {
      opacity: 0.8;
    }
  }
}

.radio-disabled {
  .input,
  .text {
    opacity: 0.5;
  }
}

.input {
  height: $size;
  width: $size;
  aspect-ratio: 1;
  border: 1px solid $border-color;
  padding: 0;
  background: $bg-color;
  border-radius: 50%;
  appearance: none;
  cursor: pointer;
  transition: 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:not(:disabled):hover {
    border-color: $active-color;
  }

  &:checked {
    border-color: $active-color;
    border-width: calc($size / 4);
    background-color: $bg-color-active;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media print {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background: none;
  }
}

.text {
  transition: 0.2s;
  flex: 1;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
}
