.tariff {
  position: relative;
  background: var(--color-fill-6);
  border-radius: var(--container-border-radius);
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
}

.tag {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1 1 auto;
}

.bottom {
  flex: 1 1 auto;
}

.mark {
  font: var(--font-2);
  font-weight: 600;
}

.name,
.price,
.control {
  display: flex;
  justify-content: center;
}
