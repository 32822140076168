.wrapper {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    justify-content: center;
    background: var(--overlay-bg-color);
}

html {
    &[data-theme="dark"] {
        .wrapper {
            background: var(--overlay-bg-dark-color);
        }
    }
}
