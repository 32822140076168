.wrapper {
  background: var(--color-fill-11);
  border-radius: 12px;

  transition-property: height, padding-bottom;

  &.open {
    padding-bottom: 20px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
  }

  .icon {
    transform: rotate(180deg);
    &.open {
      transform: rotate(0deg);
    }
  }

  .content {
    height: 0;
    overflow: hidden;

    white-space: pre-line;
    transition-property: height;
    transition-duration: 300ms;

    padding: 0 20px 0px 20px;
  }

  .actionWrapper {
    margin-top: 20px;

    .action {
      width: 100%;
    }
  }
}
