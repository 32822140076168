.wrapper {
    width: 100%;

    thead {
        width: 100%;
    }

    tbody {
        width: 100%;
    }
}

.header {
    tr {
        border: 1px solid var(--primary-b-color);
        margin-bottom: 5px;
    }
}

.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--primary-b-color);

    th,
    td {
        padding: 5px;
        text-align: start;
        text-overflow: ellipsis;
    }

    th:not(:first-child),
    td:not(:first-child) {
        border-left: 1px solid var(--primary-b-color);
    }
}
