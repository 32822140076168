.firstColumn {
    width: 100%;
    padding: 10px 0;
}

.wrapper {
    margin: 17px 0 24px;
}
@media screen and (min-width: 991px) {
    .wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}
