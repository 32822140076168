@import '../../styles/mixins/scrollbar.scss';

.layout {
  display: flex;
  flex-direction: column;
  padding: 36px;
  background-color: var(--layout-bg-color);
  height: 100vh;
  overflow: auto;
  scrollbar-gutter: stable;

  .controls {
    align-self: flex-end;
    position: absolute;

    @media screen and (max-width: 512px) {
      right: 20px;
    }
  }

  @media screen and (min-width: 769px) {
    @include scrollbar(var(--yellow-1), transparent, 8px);
  }

  @media screen and (max-width: 512px) {
    padding: 36px 8px 8px 8px;
  }
}

.header {
  z-index: 1;
  @media screen and (max-width: 512px) {
    padding: 0 12px;
  }
}

.container {
  max-width: 1173px;
  margin: 0 auto;
}

.footer-container {
  max-width: 1440px;
  margin: 0 auto;
}

.content {
  flex: 1;
}

.header + .content {
  margin-top: 60px;
}

.content + .footer {
  margin-top: 130px;
}
