.container {
  flex: 1;
  height: 100%;
  padding-top: 32px;

  :global(.framePaycos) {
    border-radius: 12px;
    overflow: hidden;
  }
}
