.slide {
  background-repeat: no-repeat;
  background-position: 374px 100%;
  background-size: contain;

  @media (max-width: 1200px) {
    background-position: 368px 100%;
  }

  @media (max-width: 1120px) {
    background-position: 354.4px 100%;
  }

  @media (max-width: 1040px) {
    background-position: 340.8px 100%;
  }

  @media (max-width: 960px) {
    background-position: 327.2px 100%;
  }

  @media (max-width: 880px) {
    background-position: 313.6px 100%;
  }

  @media (max-width: 800px) {
    background-position: 300px 100%;
  }

  @media (max-width: 768px) {
    &,
    &-mac,
    &-windows,
    &-iphone,
    &-android {
      background-image: none !important;
    }

    &::before {
      content: none !important;
    }
  }

  @at-root {
    [data-theme='dark'] & {
      &-mac {
        background-image: url('../../../../shared/assets/images/devices/mac-bg-dark.png');
      }

      &-windows {
        background-image: url('../../../../shared/assets/images/devices/windows-bg-dark.png');
      }

      &-iphone {
        background-image: url('../../../../shared/assets/images/devices/iphone-bg-dark.png');
      }

      &-android {
        background-image: url('../../../../shared/assets/images/devices/android-bg-dark.png');
      }
    }
  }

  @at-root {
    [data-theme='light'] & {
      &-mac {
        background-image: url('../../../../shared/assets/images/devices/mac-bg-light.png');
      }

      &-windows {
        background-image: url('../../../../shared/assets/images/devices/windows-bg-light.png');
      }

      &-iphone {
        background-image: url('../../../../shared/assets/images/devices/iphone-bg-light.png');
      }

      &-android {
        background-image: url('../../../../shared/assets/images/devices/android-bg-light.png');
      }
    }
  }
}

.number {
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background: var(--green-4);

  &::before {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(../../../../shared/assets/images/devices/mac-bg-dark.png)
      url(../../../../shared/assets/images/devices/windows-bg-dark.png)
      url(../../../../shared/assets/images/devices/iphone-bg-dark.png)
      url(../../../../shared/assets/images/devices/android-bg-dark.png)
      url(../../../../shared/assets/images/devices/mac-bg-light.png)
      url(../../../../shared/assets/images/devices/windows-bg-light.png)
      url(../../../../shared/assets/images/devices/iphone-bg-light.png)
      url(../../../../shared/assets/images/devices/android-bg-light.png);
  }
}

.description {
  max-width: 400px;
}

.arrow {
  transform: rotate(270deg);
  font-size: 24px;
  fill: var(--button-text-color);
}

.next {
  align-items: flex-start;
  padding-left: 15px;
}
