.wrapper {
    width: 100%;
}

.header {
    padding: 10px;
    margin-bottom: 10px;
}

.row {
    padding: 10px;
    border-radius: 15px;
    background: linear-gradient(0deg, #C9CCDA 0%, #C9CCDA 100%), #EBECF0;
    background-blend-mode: soft-light, normal;
    box-shadow: 2px 2px 3px 0 #BDC1D1 inset, -2px -2px 6px 0 #FFF inset;
}

.rowExpired {
    background: var(--secondary-b-color);
}

.error {
    padding: 30px;
}

.on {
    color: #FFF;

    &:hover {
        color: #000;
    }
}

.off {
    color: #000;
}

.button {
    padding: 16px 20px;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
        //background: var(--primary);
        color: #FFF;
    }
}

.on {
    padding: 16px 20px;
    color: var(--black);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
        //background: var(--primary);
        color: var(--black);
    }
}

.off {
    padding: 12px 20px;
    border-radius: 11px;
    background: #D9D9D9;
    color: var(--black);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
        background: var(--primary);
        color: var(--black);
    }
}

.buttonRow {
    width: 131px !important;
}

.buttonDelete {
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 9px;
}

.actions {
    flex-grow: 2;
    justify-content: flex-end;
}

.country {
    div{
        overflow: hidden;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    svg {
        margin-left: 25px;
    }
}

.fieldHeader {
    padding-left: 20px;
}

@media screen and (min-width: 992px) {
    .header {
        padding: 0;
    }

    .button {
        width: 288px;
        height: 45px;
        border-radius: 9px;
        background: linear-gradient(133deg, rgb(0 255 194 / 68%) 24.04%, #00D408 63.01%), #007604;
        font-weight: 600;
    }

    .field {
        overflow: hidden;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .field:nth-child(1) {
        width: 200px;
    }

    .field:nth-child(2) {
        width: 294px;
    }

    .field:nth-child(3) {
        width: 162px;
    }

    .field:nth-child(4) {
        width: 145px;
    }

    .row {
        align-items: center;
        padding: 6px 15px;
        border-radius: 15px;
        background: #FFF;
    }

    .rowExpired {
        background: var(--secondary-b-color);
    }
}

html {
    &[data-theme="dark"] {
        .field {
            p {
                color: var(--white);
            }
        }

        .row {
            border: 1px solid var(--secondary-b-color);
            background: var(--dark-input-bg);
            box-shadow: initial;
        }

        .rowExpired {
            background: var(--secondary-b-color);
        }

        .buttonDelete {
            border-color: transparent;
            background: var(--black);
            box-shadow: initial;
        }

        .button {
            box-shadow: initial;
        }

        .on {
            box-shadow: initial;
            color: var(--white);
            &:hover {
                color: var(--white);
            }
        }
        .off {
            color: var(--white);
            box-shadow: initial;
            &:hover {
                color: var(--white);
            }
        }
    }
}
