.Button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    color: var(--primary-text-color);
    transition: all 0.3s;
    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.outline {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--inverted-primary-bg-color);
    border-radius: 15px;
    background: transparent;
    color: var(--primary-text-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal !important;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0 0 5px 0 var(--inverted-primary-bg-color);
    }
}

.background {
    padding: 11px 0 10px;
    border-radius: 15px;
    background: var(--primary);
    color: var(--inverted-primary-text-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal !important;
    transition: all 0.3s;

    &:hover {
        background: var(--primary-bg-color);
        box-shadow: 0 0 5px 0 var(--inverted-primary-bg-color);
        color: var(--primary-text-color);
        cursor: pointer;
    }

    &:disabled {
        background: var(--disabled-bg-color);
    }

    &:disabled:hover {
        box-shadow: none;
        color: var(--inverted-primary-text-color);
        cursor: auto;
    }
}

.icon {
    padding: 0;

    &:hover {
        transform: scale(1.1);
    }
}

.square {
    padding: 0;
    border-radius: 0;
}

.square.size_m {
    width: var(--font-line-m);
    height: var(--font-line-m);
}

.square.size_l {
    width: var(--font-line-l);
    height: var(--font-line-l);
}

.square.size_xl {
    width: var(--font-line-xl);
    height: var(--font-line-xl);
}

.maxWidth {
    width: 100%;
}

.loader {
    display: inline-block;
    width: 28px;
    height: 28px;
    box-sizing: border-box;
    border: 3px solid #FFF;
    border-radius: 50%;
    border-bottom-color: transparent;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

html {
    &[data-theme="dark"] {
        .Button {
            box-shadow: -7px -7px 15px 0 rgb(60 60 60 / 50%), 7px 7px 15px 0 rgb(7 7 7 / 50%);
            color: #fff;
            transition: all ease-in 0.3s;

            &:hover {
                box-shadow: -7px -7px 15px 0 rgb(80 80 80 / 50%), 7px 7px 15px 0 rgb(20 20 20 / 50%);
            }
        }

        .background:hover {
                border-color: var(--primary);
                background: var(--primary);
        }
    }
}
