.button {
  display: flex;
  color: var(--white);
  width: 90px;
  height: 35px;
  border-radius: 9px;
  background: linear-gradient(133deg, rgba(0, 255, 194, 0.68) 24.04%, #00D408 63.01%), #007604;
  font-weight: 600;
}

.icon {
  margin-left: 10px;
}

html {
  &[data-theme="dark"] {
    .button {
      box-shadow: none;
    }
  }
}
