.slide {
  position: relative;
}

.container {
  margin-top: 156px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.success {
  font-size: 56px;
}

.title {
  white-space: break-spaces;
  text-align: center;
}

.ok {
  padding: 0 20px;
}

.thanks {
  align-items: flex-start;
  padding: 9px 56px;
}

.image {
  max-width: 256px;
}

.text {
  text-align: center;
}

.modal-content {
  margin-top: 42px;
  padding: 0 20px;
}
