.group {
  --radio-size: 32px;
  max-width: 448px;

  .item {
    background: var(--color-fill-3);
    padding: 22px;
    border-radius: var(--container-border-radius);
    margin: 1px;
    transition: background 0.3s ease;
  }

  .item-active {
    background: var(--color-fill-11);
    border: 1px solid var(--primary-color);
    margin: 0;
  }

  .clock {
    font-size: 20px;
  }
}

.description {
  max-width: 276px;
  white-space: pre-line;
}

.benefits {
  margin-top: 8px;
  display: block;
}

.recommended {
  display: flex;
  color: var(--primary-color);
  background: color-mix(in srgb, currentColor 10%, transparent);
  border: 1px solid;
  border-color: color-mix(in srgb, currentColor 20%, transparent);
  padding: 5px;
  height: 27px;
  border-radius: 12px;
  align-items: center;
  width: fit-content;
}

.popover {
  pointer-events: none;
}
