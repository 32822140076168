.wrapper {
  align-items: center;
  text-align: center;

  .icon {
    margin-top: 80px;
  }

  .heading {
    margin-top: 32px;
  }

  .hint {
    margin-top: 32px;
  }

  .button {
    margin-top: 48px;
  }
}
