.list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 22px 0 15px;
    gap: 20px;

    img {
        max-width: 49px;
        max-height: 49px;
    }
}
.iconWrapper {
    color: var(--primary)
}

@media screen and (min-width: 992px) {
    .list {
        position: absolute;
        top: 42px;
        left: 79px;

        img {
            max-width: 67px;
            max-height: 67px;
            transform: translateY(0);
        }

        .mac {
            transform: translateY(0);
        }
    }
}
