.wrapper {
    padding: 0 6px;
    margin: 30px 0;
}

.header {
    margin-bottom: 5px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.field {
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
}

.input {
    border-radius: 50px;
    margin-top: 7px;
    background: linear-gradient(0deg, #C9CCDA 0%, #C9CCDA 100%), #EBECF0;
    background-blend-mode: soft-light, normal;
    box-shadow: 2px 2px 3px 0 #BDC1D1 inset, -2px -2px 6px 0 #FFF inset;
}

.button {
    width: 100%;
    padding: 15px 0;
    border-radius: 15px;
    margin-top: 7px;
    background: linear-gradient(133deg, rgb(0 255 194 / 68%) 24.04%, #00D408 63.01%), #007604;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.link {
    color: var(--primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 110.5%;
    text-decoration-line: underline;
}

.inputError {
    p {
        margin: 5px 10px;
        font-size: 14px !important;
    }
}

@media (min-width: 992px) {
    .wrapper {
        padding: 0 30px;
    }

    .content {
        flex-direction: row;
        align-items: center;
        gap: 32px;
    }

    .info {
        width: 34%;
    }

    .button {
        width: 232px;
        max-height: 51px;
        padding: 14px 33px;
        margin-top: 0;
        font-size: 22px;
        font-weight: 600;
    }
}

@media screen and (min-width: 1440px) {
    .info {
        width: 395px;
    }
}
