.wrapper {
  gap: 28px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link {
  align-self: center;
}
