.button {
  background: var(--color-fill-4);
  width: 60px;
  font-size: 26px;
}

.content {
  min-width: 303px;
}

.question {
  fill: var(--font-color-primary);
}
