.payModal {
    position: fixed;
    z-index: 20;
    inset: 0;
    pointer-events: none;
}

.loader {
    position: absolute;
    display: inline-block;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border: 5px solid #fff;
    border-radius: 50%;
    border-bottom-color: transparent;
    animation: rotation 1s linear infinite;
}

.overlay {
    align-items: center;
    pointer-events: auto;
}

.defaultModal {
    display: flex;
    overflow: auto;
    min-width: 400px;
    min-height: 450px;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 8px;
    margin: auto;
    background: var(--primary-bg-color);
    transition: 0.3s transform;
}
.modalPaycos {
    position: relative;
    width: 100%;
    height: 100%;
}


.hidden-overflow {
    overflow: hidden;
}

.close-modal-state {
    transform: scale(0);
}

.content {
    display: flex;
    overflow: auto;
    min-width: 600px;
    min-height: 450px;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

.paymentLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .content {
        min-width: 300px;
        min-height: 250px;
    }
    .defaultModal {
        min-width: 300px;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
