.number {
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background: var(--green-4);

  &::before {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(../../../../shared/assets/images/backgrounds/config-bg-dark.png)
      url(../../../../shared/assets/images/backgrounds/config-bg-light.png)
      url(../../../../shared/assets/images/backgrounds/manual-bg-dark.png)
      url(../../../../shared/assets/images/backgrounds/manual-bg-light.png)
      // TODO: add light app bg
      url(../../../../shared/assets/images/backgrounds/app-bg-dark.png);
  }
}

.slide {
  background-repeat: no-repeat;
  background-position: 374px 100%;
  background-size: contain;

  @media (max-width: 1200px) {
    background-position: 368px 100%;
  }

  @media (max-width: 1120px) {
    background-position: 354.4px 100%;
  }

  @media (max-width: 1040px) {
    background-position: 340.8px 100%;
  }

  @media (max-width: 960px) {
    background-position: 327.2px 100%;
  }

  @media (max-width: 880px) {
    background-position: 313.6px 100%;
  }

  @media (max-width: 800px) {
    background-position: 300px 100%;
  }

  @media (max-width: 768px) {
    &,
    &-mac,
    &-windows,
    &-iphone,
    &-android {
      background-image: none !important;
    }

    &::before {
      content: none !important;
    }
  }

  @at-root {
    [data-theme='dark'] & {
      &-app {
        background-image: url(../../../../shared/assets/images/backgrounds/app-bg-dark.png);
      }

      &-config {
        background-image: url(../../../../shared/assets/images/backgrounds/config-bg-dark.png);
      }

      &-manual {
        background-image: url(../../../../shared/assets/images/backgrounds/manual-bg-dark.png);
      }
    }
  }

  @at-root {
    [data-theme='light'] & {
      &-config {
        background-image: url(../../../../shared/assets/images/backgrounds/config-bg-light.png);
      }

      &-manual {
        background-image: url(../../../../shared/assets/images/backgrounds/manual-bg-light.png);
      }
    }
  }
}

.description {
  max-width: 400px;
}

.arrow {
  font-size: 24px;
  fill: var(--font-color-primary);
}

.next,
.back {
  align-items: flex-start;
}

.next {
  padding-left: 15px;
  .arrow {
    transform: rotate(270deg);
    fill: var(--button-text-color);
  }
}

.back {
  background: var(--button-color-secondary);
  padding-right: 15px;

  &:not(:disabled):hover {
    box-shadow: 0 0 4px var(--button-color-secondary);
  }

  .arrow {
    transform: rotate(90deg);
    fill: var(--button-text-color-secondary);
  }
}
