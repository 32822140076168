.wrapper {
    display: flex;
    max-width: min-content;
    flex-direction: column;
    gap: 7px;
}

.server {
    display: flex;
    border-radius: 50px;
    background: transparent;
    background-blend-mode: soft-light, normal;
}

.icon {
    margin-left: 17px;
}

@media screen and (min-width: 992px) {
    .server {
        overflow: hidden;
        max-width: 287px;
        justify-content: space-between;
        padding: 15px 20px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 2px 2px 3px 0 #BDC1D1 inset, -2px -2px 6px 0 #FFF inset;
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

html {
    &[data-theme="dark"] {
        @media screen and (min-width: 992px) {
            .server {
                border: 1px solid var(--secondary-b-color);
                background: transparent;
                box-shadow: initial;
                color: var(--white)
            }
        }
    }
}
