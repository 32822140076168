.button {
  background: var(--color-fill-4);
  width: 60px;
  font-size: 26px;
}

.profile {
  path {
    fill: var(--font-color-primary);
  }
}

.triangle {
  font-size: 8px;

  path {
    fill: var(--font-color-primary);
  }
}

.content {
  max-width: 303px;
  width: 100%;
}

.group + .group {
  border-top: 1px solid var(--border-color-2);
}

.group:not(:first-child) {
  padding-top: 20px;
}

.group:not(:last-child) {
  padding-bottom: 20px;
}
