.wrapper {
    width: 100%;
}

.footer {
    padding: 5px 2px;
    margin-top: 10px;
}


.card {
    padding: 8px;
    border-radius: 15px;
    background: #FFF;
}

.field {
    padding: 6px;
    border-bottom: 1px solid #000;
}

.label {
    color: currentcolor;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.country {
    div{
        overflow: hidden;
        width: 253px;
        border: none;
        color: #000;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    svg {
        margin-left: 5px;
    }
}

.button {
    padding: 12px 20px;
    border-radius: 11px;
    background: var(--primary);
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
        background: var(--primary);
    }
}

.on {
    padding: 16px 20px;
    color: var(--black);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
        //background: var(--primary);
        color: var(--black);
    }
}

.off {
    padding: 12px 20px;
    border-radius: 11px;
    background: #D9D9D9;
    color: var(--black);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
        background: var(--primary);
        color: var(--black);
    }
}

.buttonAddOn {
    width: 50px;
    border-radius: 11px;
}

.rowExpired {
    background: var(--secondary-b-color);
}

html {
    &[data-theme="dark"] {
        .card {
            border: 1px solid var(--secondary-b-color);
            background: var(--dark-input-bg);
            color: var(--white);
        }

        .rowExpired {
            background: var(--secondary-b-color);
        }

        .field {
            border-color: var(--secondary-b-color);
            color: var(--white);
        }

        .label {
            p {
                color: var(--white);
            }
        }

        .buttonAddOn {
            border-color: transparent;
            background: var(--black);
        }

        .button {
            background: var(--black);
        }

        .on {
            box-shadow: initial;
            color: var(--white);
            &:hover {
                color: var(--white);
            }
        }
        .off {
            color: var(--white);
            box-shadow: initial;
            &:hover {
                color: var(--white);
            }
        }
    }
}
