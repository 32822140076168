$input-font-size: var(--input-font-size, 16px);
$input-padding: var(--input-padding, 10px);
$input-border-radius: var(--input-border-radius, 8px);
$input-fill-color: var(--input-fill-color);
$input-font-color: var(--input-font-color);

$input-label-font-size: var(--input-font-size, 16px);
$input-label-font-size-active: calc(var(--input-font-size, 16px) * 0.75);
$input-label-color: var(--input-label-color);
$input-label-color-active: var(--input-label-color-active);

$input-wrapper-padding-top: calc($input-label-font-size / 2);

$input-border-width: 1px;
$input-border-width-scale: 1px;
$input-border-width-active: calc(
  $input-border-width + $input-border-width-scale
);
$input-border-color: var(--input-border-color);
$input-border-color-active: var(--input-border-color-active);

$input-scale-coefficient: 1.2;
$label-margin-top: calc(
  (
      $input-font-size * $input-scale-coefficient + $input-padding * 2 +
        $input-border-width - $input-label-font-size
    ) / 2 + $input-wrapper-padding-top
);

.input__wrapper {
  position: relative;

  &:not(.input__wrapper--no-label) {
    padding-top: $input-wrapper-padding-top;
    input::placeholder {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    &:focus-within input::placeholder {
      opacity: 1;
    }
  }

  .input {
    display: flex;
    border-style: solid;
    border-width: $input-border-width;
    border-color: $input-border-color;
    transition: border-color 0.2s ease;

    input {
      width: 100%;
      font-size: $input-font-size;
      outline: none;
      border: none;
      background-color: transparent;
      color: $input-font-color;
    }

    &:hover {
      border-color: $input-border-color-active;
    }

    &:focus-within {
      border-width: $input-border-width-active;
      border-color: $input-border-color-active;
      margin: 0;
    }

    &:focus-within + .input__label {
      top: 0;
      font-size: $input-label-font-size-active;
      color: $input-label-color-active;
    }

    &__label {
      position: absolute;
      vertical-align: bottom;
      left: 6px;
      color: $input-label-color;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      padding: 0 4px;
      font-size: $input-label-font-size;
      line-height: $input-label-font-size;
    }

    &__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .input--has-value {
    border-width: $input-border-width-active;
    margin: 0;

    &:hover {
      border-color: $input-border-color-active;
    }
  }

  .input--has-value + .input__label {
    top: 0;
    font-size: $input-label-font-size-active;
  }
}

.outlined-input__wrapper {
  .input {
    background-color: $input-fill-color;
    border-radius: $input-border-radius;
    overflow: hidden;

    //резерв под увеличенный border
    &:not(&--has-value):not(&:focus-within) {
      margin: $input-border-width-scale;
    }

    &__label {
      top: calc(
        $label-margin-top + ($input-border-width + $input-border-width-scale) /
          2
      );
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        $input-fill-color 50%,
        $input-fill-color 100%
      );
    }

    input {
      padding: $input-padding;
    }
  }
}

.filled-input__wrapper {
  border-radius: $input-border-radius $input-border-radius 0 0;
  background-color: $input-fill-color;
  .input {
    border-left: none;
    border-top: none;
    border-right: none;
    //резерв под увеличенный border
    &:not(&--has-value):not(:focus-within) {
      margin-bottom: $input-border-width-scale;
    }

    &__label {
      top: calc($label-margin-top - $input-wrapper-padding-top * 0.75);
    }

    &__suffix {
      margin-top: $input-wrapper-padding-top;
      transition: all 0.3s ease;
    }

    &:focus-within + .input__label {
      background-color: $input-fill-color;
    }

    input {
      padding: $input-padding $input-padding calc($input-padding / 4)
        $input-padding;
    }
  }

  .input--has-value + .input__label {
    background-color: $input-fill-color;
  }
}

.eye {
  font-size: 24px;
  fill: var(--color-fill-10);
}
