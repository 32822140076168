.container {
  margin-top: 98px;
  text-align: center;
  white-space: pre-line;
}

.free {
  color: var(--primary-color);
}

.button {
  align-self: normal;
}
