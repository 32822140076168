.wrapper {
  width: auto;
}

.header {
  margin-bottom: 20px;
  font-size: 28px;
  text-align: center;
}

.select {
  width: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
  background: var(--primary);
}

.selectHeader {
  border-color: transparent;
}

.selectPopup {
  left: 0
}

.selectOption {
  height: 50px;
  display: flex;
  align-items: center;
}

.info {
  padding: 0 20px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  padding: 15px 50px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    width: 400px;
  }

  .select {
    width: 380px;
  }
}
