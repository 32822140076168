.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
}

.picture {
    width: 74px;
    height: 74px;
}

