.description {
  font: var(--font-2);
  color: var(--font-color-primary);
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.row {
  background-color: var(--color-fill-2);
  border-radius: 10px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--border-color-4);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    flex: 1 1 calc(50% - 8px);
  }
}

.label {
  color: var(--font-color-secondary);
}

.value {
  font-weight: 600;
}

.highlighted {
  font-weight: 600;
  color: var(--green-1);
}
