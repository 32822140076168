.checkbox {
  color: var(--checkbox-font-color);
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  user-select: none;
  font-size: var(--checkbox-font-size);
  height: var(--checkbox-size);
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: var(--checkbox-color-active);

    &:after {
      display: block;
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  background-color: var(--checkbox-color);
  border-radius: 4px;
  transition: all 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
