.Modal {
    position: fixed;
    z-index: -1;
    inset: 0;
    opacity: 0;
    pointer-events: none;
}

.content {
    display: flex;
    overflow: auto;
    min-width: 200px;
    min-height: 250px;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 8px;
    margin: auto;
    background: var(--primary-bg-color);
    transform: scale(0.5);
    transition: 0.3s transform;
}

.opened {
    z-index: var(--modal-z-index);
    opacity: 1;
    pointer-events: auto;
}

.contentOpen {
    transform: scale(1);
}

.contentClose {
    transform: scale(0);
}

.buttons {
    margin-top: 15px;
    margin-left: auto;
}

.closeButton {
    width: auto;
    padding: 0;
    border: none;
}
.actionButton {
    border: 2px solid var(--primary);
    border-radius: 15px;
    margin-top: 11px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px 15px;
}

html {
    &[data-theme="dark"] {
        .content {
            background-color: var(--dark-bg-color);
            color: var(--white);
        }
    }
}
