.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link {
  align-self: center;
}

.checkbox {
  flex: 1;
}

.agreement {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.button {
  margin-top: 10px;
}
