$green: var(--green-1);
$yellow: var(--yellow-1);
$white: var(--white);

@mixin dots($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $green;
  box-shadow: $size 0 $green, -$size 0 $green;
  position: relative;
  animation: flash 0.75s ease-out infinite alternate;

  @keyframes flash {
    0% {
      background-color: $yellow;
      box-shadow: $size * 2 0 $yellow, -$size * 2 0 $green;
    }
    50% {
      background-color: $green;
      box-shadow: $size * 2 0 $yellow, -$size * 2 0 $yellow;
    }
    100% {
      background-color: $yellow;
      box-shadow: $size * 2 0 $green, -$size * 2 0 $yellow;
    }
  }
}

@mixin squares($size) {
  display: block;
  position: relative;
  height: $size;
  width: $size * 7;
  background-image: linear-gradient($white $size, transparent 0),
    linear-gradient($white $size, transparent 0),
    linear-gradient($white $size, transparent 0),
    linear-gradient($white $size, transparent 0);
  background-repeat: no-repeat;
  background-size: $size auto;
  background-position: 0 0, $size * 2 0, $size * 4 0, $size * 6 0;
  animation: pg-fill 1s linear infinite;

  @keyframes pg-fill {
    0% {
      background-image: linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0);
    }
    25% {
      background-image: linear-gradient($green $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0);
    }
    50% {
      background-image: linear-gradient($white $size, transparent 0),
        linear-gradient($green $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0);
    }
    75% {
      background-image: linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($green $size, transparent 0),
        linear-gradient($white $size, transparent 0);
    }
    100% {
      background-image: linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($white $size, transparent 0),
        linear-gradient($green $size, transparent 0);
    }
  }
}

@mixin spin($size) {
  width: $size;
  height: $size;
  border: 5px solid $green;
  border-bottom-color: $white;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@mixin double-spin($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid $white;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: $size;
    height: $size;
    border-radius: 50%;
    border-left: 4px solid $green;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots {
  min-width: 60px;
}

.dots.small {
  @include dots(12px);
}

.dots.middle {
  @include dots(20px);
}

.dots.large {
  @include dots(28px);
}

.loading-spin {
  display: flex;
}

.spin.small {
  @include spin(24px);
}

.spin.middle {
  @include spin(32px);
}

.spin.large {
  @include spin(48px);
}

.loading-double-spin.loading-small {
  height: 28px;
  width: 28px;
}

.loading-double-spin.loading-middle {
  height: 36px;
  width: 36px;
}

.loading-double-spin.loading-large {
  height: 52px;
  width: 52px;
}

.double-spin.small {
  @include double-spin(24px);
}

.double-spin.middle {
  @include double-spin(32px);
}

.double-spin.large {
  @include double-spin(48px);
}

.squares.small {
  @include squares(12px);
}

.squares.middle {
  @include squares(24px);
}

.squares.large {
  @include squares(32px);
}
